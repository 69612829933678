<template>
    <div class="team_main">

        <van-nav-bar class="nav-bar">
            <template #left>
                <!-- <van-icon name="arrow-left" color="#fff" @click="back()" /> -->
                <van-icon name="wap-home-o" @click="toHome" color="#333" size="36" />
            </template>
            <template #right>
                <img style="width: 36px;" @click="toServe" src="/img/au/kefu.png" />
            </template>
        </van-nav-bar>
        
        <van-nav-bar class="nav-bar" :title="$t('xuanfei.tdbb')">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
            <!-- <template #right>
                <img style="width: 36px;" @click="toServe" src="/img/au/kefu.png" />
            </template> -->
        </van-nav-bar>

        <van-tabs v-model="tabs_active" @change="tabsChange" type="card" style="margin-top: 30px;border-radius: 20px;">
            <van-tab :title="item.name" v-for="(item,index) in tabsList" :key="index"></van-tab>
        </van-tabs>
        <!-- <div class="tabs_checked" @click="showCalendar = true">

            <van-icon name="clock-o" size="26" style="margin-right: 6px;" />{{ $t("index.all") }}
        </div> -->

        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
            :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
            <van-list v-model="loading"  v-if="list" :finished="finished" :offset="50" :immediate-check="false"
                :finished-text="$t('video.no_more')" @load="onLoad" :loading-text="$t('refresh.loading')">
                <div class="team_list">
                    <div class="group_cell" style="border-bottom: 1px solid #666;">
                        <div class="group_left">{{ $t("index.all") }}</div>
                        <!-- <div class="group_right" style="color: #6d00be;">0</div> -->
                    </div>
                    <div class="group_cell">
                        <div class="group_left">{{list.team.total}}</div>
                        <div class="group_right">{{list.team.money}}</div>
                    </div>
                    <div class="group_cell">
                        <div class="group_left">{{ $t('xuanfei.tdrs') }}</div>
                        <div class="group_right">{{ $t('xuanfei.tdyj') }}({{ $t("reservation.unit") }})</div>
                    </div>
                </div>
                <div class="team_list">
                    <div class="group_cell" style="border-bottom: 1px solid #666;">
                        <div class="group_left">{{$t("xuanfei.lv1")}} <span class="bili1" v-if="list.team1.rate">{{ $t("xuanfei.bili") }}{{list.team1.rate}}%</span></div>
                        <!-- <div class="group_right" style="color: #6d00be;">0</div> -->
                    </div>
                    <div class="group_cell">
                        <div class="group_left">{{list.team1.total}}</div>
                        <div class="group_right">{{list.team1.money}}</div>
                    </div>
                    <div class="group_cell">
                        <div class="group_left">{{ $t('xuanfei.tdrs') }}</div>
                        <div class="group_right">{{ $t('xuanfei.tdyj') }}({{ $t("reservation.unit") }})</div>
                    </div>
                </div>
                
                <div class="team_list">
                    <div class="group_cell" style="border-bottom: 1px solid #666;">
                        <div class="group_left">{{$t("xuanfei.lv2")}} <span class="bili2" v-if="list.team2.rate">{{ $t("xuanfei.bili") }}{{list.team2.rate}}%</span></div>
                        <!-- <div class="group_right" style="color: #6d00be;">0</div> -->
                    </div>
                    <div class="group_cell">
                        <div class="group_left">{{list.team2.total}}</div>
                        <div class="group_right">{{list.team2.money}}</div>
                    </div>
                    <div class="group_cell">
                        <div class="group_left">{{ $t('xuanfei.tdrs') }}</div>
                        <div class="group_right">{{ $t('xuanfei.tdyj') }}({{ $t("reservation.unit") }})</div>
                    </div>
                </div>
                
                <div class="team_list">
                    <div class="group_cell" style="border-bottom: 1px solid #666;">
                        <div class="group_left">{{$t("xuanfei.lv3")}} <span class="bili3" v-if="list.team3.rate">{{ $t("xuanfei.bili") }}{{list.team3.rate}}%</span></div>
                        <!-- <div class="group_right" style="color: #6d00be;">0</div> -->
                    </div>
                    
                    <div class="group_cell">
                        <div class="group_left">{{list.team3.total}}</div>
                        <div class="group_right">{{list.team3.money}}</div>
                    </div>
                    <div class="group_cell">
                        <div class="group_left">{{ $t('xuanfei.tdrs') }}</div>
                        <div class="group_right">{{ $t('xuanfei.tdyj') }}({{ $t("reservation.unit") }})</div>
                    </div>
                </div>
            </van-list>

        </van-pull-refresh>
        <van-calendar v-model="showCalendar" type="range" @confirm="onConfirm" color="#6833ff" :readonly="topcheck != 0"
            :min-date="new Date(2010, 0, 1)" />

    </div>
</template>

<script>

export default {
    data() {
        return {
            info: {
            },
            topcheck: 0,
            tabs_active: 0,
            showCalendar: false,
            loading: false,
            finished: false,
            isLoading: true,
            list:null,
            order_show: false,
            
            tabsList: [{
                name:this.$t('index.all'),
                id: ''
            }, {
                name: this.$t('xuanfei.zt'),
                id: 'yesterday'
            }, {
                name: this.$t('xuanfei.jt'),
                id: 'today'
            }, {
                name: this.$t('xuanfei.bz'),
                id: 'week'
            }],
            tabsListIndex:0
        }
    },
    created(){

        this.getNhomeList()
    },
    methods: {
        tabsChange(e){
            console.log(e);
           this.tabsListIndex =  e
           this.list = null
           this.getNhomeList()
        },
    back(){
      return window.history.back();
    },
        toServe() {
            window.open(this.$store.getters.getBaseInfo.kefu)
        },
        toHome() {
            this.$router.push("/")
        },
        onConfirm() {
            this.showCalendar = false
        },
        

        getNhomeList() {
            
        this.$http({
            method: 'get',
            url: 'team',
                data: { type: this.tabsList[this.tabsListIndex].id },
        }).then(res => {
            console.log(res.data,444)
            this.list = res.data
        })
            // this.$http({
            //     method: 'get',
            //     url: 'xuanfeilist',
            //     data: { page: this.page },
            // }).then(res => {
            //     console.log(res.data, 999)
            //     this.loading = false;
            //     if (this.page == 1) {
            //         this.list = res.data;
            //     } else {

            //         this.list = this.list.concat(res.data);
            //     }
            //     if (res.data.length == 0) {
            //         this.finished = true;
            //     } else {
            //         this.finished = false;
            //     }
            //     this.count = res.data.count;
            //     this.page++;
            // })


        },
        onRefresh() {
            setTimeout(() => {
                this.page = 1
                this.getNhomeList();
                this.isLoading = false;
                this.$toast(this.$t('reservation.refresh'));
            }, 500);
        },
        onLoad() {
            this.loading = true;
            this.getNhomeList();
            let timer = setTimeout(() => {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                // this.loading = false;
                if (this.list.length === this.count) {
                    this.finished = true;
                }
                this.finished && clearTimeout(timer);//清除计时器
            }, 500);
        },
    }
}

</script>
<style lang="less" scoped>
::v-deep .van-tab {
    font-size: 30px;
    line-height: 100px;
    font-weight: bold;
}

::v-deep .van-tabs__line {
    background-color: #775fd9;
}

::v-deep .van-tabs--line .van-tabs__wrap {
    // height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
    padding: 0 23px;
}

::v-deep .van-tabs__nav--card {
    height: 60px;
    border-radius: 50px;
}

::v-deep .van-tabs--card>.van-tabs__wrap {
    height: 60px;
}

::v-deep .van-tabs__nav--card .van-tab.van-tab--active {
    background: #51028b;
    border-radius: 50px;
}

::v-deep .van-tabs__nav--card .van-tab {
    border-right: 0;
    background: #6d00be;
    border-radius: 50px;
    color: #fff;
    font-size: 28px;
}

::v-deep .van-tabs__nav--card {
    border: 1px solid #6d00be;
}

::v-deep .van-tabs__nav {
    background: #6d00be;
}

.tabs_checked {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}


.group_cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: 20px 0;
    padding: 15px 0;
    font-size: 26px;
}

.group_left {
    color: #333;

}

.group_right {
    color: #999;
}
.team_list{
    margin: 30px ;
    background: #fff;
    padding: 20px 30px;
    border-radius: 20px;
}
.team_main{
    background: #fffeef;
    height: 100vh;
    overflow-y: auto;
}
.bili1{
background: #148fff;
font-size: 20px;
padding: 6px 20px;
border-radius: 20px;
color: #fff;
margin-left: 10px;
}
.bili2{
background: #05b532;
font-size: 20px;
padding: 6px 20px;
border-radius: 20px;
color: #fff;
margin-left: 10px;

}
.bili3{
background: #ed6566;
font-size: 20px;
padding: 6px 20px;
border-radius: 20px;
color: #fff;
margin-left: 10px;

}
</style>