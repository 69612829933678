<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('recharge.recharge')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>

        <template #right>
          <span class="nav-right" @click="$router.push({ path: '/RechargeRecord' })">{{ $t("recharge.with_record") }}</span>
        </template>
      </van-nav-bar>
      <!-- <div class="info">
        <p class="title">
          {{ $t("recharge.curr_balance") }}({{ $t("reservation.unit") }})
        </p>
        <p class="value">{{ this.balance }}{{ $t("reservation.unit") }}</p>
      </div> -->
      <div class="recharge_header">
        <div class="recharge_header_t">{{ $t("xuanfei.wdye") }}</div>
        <div class="recharge_header_c recharge_header_money">{{ $t("reservation.unit") }} {{ userInfo.money }}</div>
          <div class="recharge_header_b">
            <div style="flex: 1;">
              
              <div>{{ $t("xuanfei.czz") }}</div>
              <div class="recharge_header_money">{{ $t("reservation.unit") }} 1</div>
            </div>
            <div style="flex: 1;">
              <div>
                {{ $t("xuanfei.ljcz") }}
              </div>
              <div class="recharge_header_money">{{ $t("reservation.unit") }} 2</div>
            </div>
          </div>
      </div>

      <div class="content recharge">
        <van-form @submit="onSubmit">
          <div class="form-item">
            <div class="form-item-title">{{ $t("recharge.input_money") }}</div>
            <div>
              <van-field style="font-size:15px" v-model="money" name="money" label=""
                :placeholder="$t('recharge.input_money')" />
            </div>
          </div>

          <div class="checked_list">
            <div class="checked_list_item" v-for="(item, index) in checked_money" :key="index"
              @click="checkIndex = index; money = item" :class="checkIndex == index ? 'active' : ''"> {{ item }}</div>
          </div>
          <div class="form-item">
            <!-- <div class="form-item-title">{{ $t("recharge.pay_way") }}</div>
            <div>
              <van-radio-group v-model="pay_way">
                <van-radio name="Mexicopay">MexicoPay</van-radio>
              </van-radio-group>
            </div> -->
            <div style="margin: 16px">
              <van-button round block type="info" native-type="submit" class="subBtn">{{ $t('my.recharge') }}</van-button>
            </div>
          </div>
        </van-form>
      </div>
      <div class="recharge_lab" v-html="recharge_lab">
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Form } from "vant";
import { Field } from "vant";
import { RadioGroup, Radio } from "vant";
Vue.use(Form).use(Field).use(RadioGroup).use(Radio);
export default {
  data() {
    return {
      recharge_lab:this.$t("xuanfei.recharge_lab"),
      balance: 0,
      pay_way: "Mexicopay",
      win_money: 0,
      money: "",
      personalreport: {},
      checked_money: [
        10, 30, 50, 100, 300, 500, 1000, 2000, 3000, 5000, 10000, 50000
      ],
      checkIndex: 0,
      userInfo:{}
    };
  },
  mounted() {
    this.balance = this.$route.params.balance;
  },
  methods: {
    back() {
      return window.history.back();
    },

    //拉起支付
    onSubmit(values) {
      const money = values.money;
      if (money <= 0) {
        this.$toast(this.$t("reservation.money_err"));
        return;
      }
      this.$router.push({ path: '/Channel?money=' + values.money });

    },

    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          console.log(res.data)
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t("video.account_out"));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getPersonalreport() {
      this.$http({
        method: "get",
        url: "user_get_personalreport",
      }).then((res) => {
        if (res.code === 200) {
          this.personalreport = res.data;
          this.win_money =
            this.personalreport.win_money - this.personalreport.play_money;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getPersonalreport();
      this.getUserInfo()
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.container .header {
  // background: linear-gradient(270deg, #c24491, #775fd9);
}

.recharge {
  padding: 10px 30px;
}

.van-cell {
  line-height: 65px !important;
}

.van-button {
  height: 87px !important;
}

.van-button__text {
  color: #fff !important;
}

/deep/.van-radio__icon {
  font-size: 30px !important;
}

/deep/.van-radio__label {
  margin-left: 25px !important;
  font-size: 35px !important;
}

/deep/.van-radio {
  height: 65px !important;
}

.form-item {
  margin-top: 40px;
}

.form-item-title {
  font-size: 36px;
  font-weight: bold;
  color: #999;
  margin-bottom: 20px;
}

.recharge span {
  font-size: 4vw;
  color: #868686;
  font-weight: 500;
}

.container .header .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: auto;
}

.container .header .info .title {
  font-size: 25px;
  color: #e5e5e5;
}

.container .header .info .value {
  margin: 10px auto;
  color: #fff;
  font-size: 50px;
  border-bottom: 1px solid #fff;
}

.container .header .info .tip {
  font-size: 30px;
  color: #e5e5e5;
}

.container .content {
  flex: 1;
  background: #f2f2f5;
}

.subBtn {
  background: #3693fa;
}

.checked_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 30px 0;
}

.checked_list_item {
  width: calc(25% - 40px);
  text-align: center;
  background: #3693fa;
  margin: 10px 20px;
  border-radius: 20px;
  color: #fff;
  padding: 10px 20px;
  font-size: 26px;
}

.recharge_header{
  background: #3395fa;
  border-radius: 20px;
  margin: 20px;
  color: #fff;
  padding: 30px;
  font-size: 28px;
}
.recharge_header_t{
  margin-bottom: 20px;
  font-size: 28px;
}
.recharge_header_b{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recharge_header_money{
  font-size: 30px;
  font-weight: bold;
  margin-top: 10px;
}
.recharge_header_c{
  margin-bottom: 20px;
  text-align: center;
  margin: 60px 0;
}
.recharge_lab{
  margin: 30px;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 20px 2px #eee;
  border-radius: 20px;
  font-size: 26px;
  color: #999;
  line-height: 1.5;
}
</style>
