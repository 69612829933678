<template>
    <div class="tel">

        <van-nav-bar class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
                <!-- <van-icon name="wap-home-o" @click="toHome" color="#333" size="36" /> -->
            </template>
            <!-- <template #right>
                <img style="width: 36px;" @click="toServe" src="/img/au/kefu.png" />
            </template> -->
        </van-nav-bar>

        <div class="yqhy">
            {{ $t('xuanfei.yqhylqyj') }}</div>
        <div class="content">
            <div class="top">
                <div class="title">
                    <img src="/img/au/money.png" alt="" class="img" width="20">
                    <img src="/img/au/money.png" alt="" class="img" width="20">
                    <img src="/img/au/money.png" alt="" class="img" width="20">
                </div>
                <div class="c" v-html="yq_lab"></div>
                <!-- <div class="b">
                    {{$t('xuanfei.tgm')}} <span class="span">{{info.invite_code}}</span>
                </div> -->
                <div class="bottom" @click="copyLink">{{ $t('xuanfei.tgm') }}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            info: {
                invite_code: '',
                url: ""
            },
            userInfo:{},
            yq_lab: this.$t('xuanfei.yq_lab')
        }
    },
    created(){

        this.getUserInfo()
    },
    methods: {
        
        getUserInfo() {
            this.$http({
                method: 'get',
                url: 'user_info'
            }).then(res => {
                if (res.code === 200) {
                    this.userInfo = res.data;
                    console.log(res.data,888899)
                    if (this.userInfo.status !== 1) {
                        this.$toast(this.$t("video.account_out"));
                        localStorage.clear()
                        this.$router.push({ path: '/Login' })
                    }
                } else if (res.code === 401) {
                    this.$toast(res.msg);
                }
            })
        },
        back() {
            return window.history.back();
        },
        copyLink() {
            //获取需要复制的文本内容。
            const text = window.location.origin + '?invite_code=' + this.userInfo.id;
            // 创建一个临时的 textarea 元素。
            const el = document.createElement('textarea');
            // 将需要复制的文本内容赋值给 textarea 元素。
            el.value = text;
            //将 textarea 元素添加到页面中。
            document.body.appendChild(el);
            //选中 textarea 元素中的文本。
            el.select();
            // 执行复制命令，将选中的文本复制到剪贴板
            document.execCommand('copy');
            //从页面中移除 textarea 元素。
            document.body.removeChild(el);
            //弹出提示框，告知用户文本已复制成功。
            this.$toast(this.$t('xuanfei.copy_sccuss'));
        },
        toHome() {
            this.$router.push("/")
        },
        toServe() {
            window.open(this.$store.getters.getBaseInfo.kefu)
        },
    }
}

</script>
<style lang="less" scoped>
.tel {
    overflow: hidden;
    // background-image: url("~@/assets/images/self/share.png");
    // background-size: 100% 100%;
    position: relative;
    // background-color: #000000;
    text-align: center;

    .yqhy {
        height: 400px;
        width: 100%;
        background-color: #ffb900;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        font-size: 50px;
        padding-top: 60px;
    }

    :deep(.van-nav-bar) {
        position: absolute !important;
        width: 100%;
        background-color: inherit;
        color: #fff;
        z-index: 10;

        .van-nav-bar__left {
            .van-icon {
                color: #fff;
            }
        }

        .van-nav-bar__title {
            color: #fff;
        }

        .van-nav-bar__right {
            .van-icon {
                color: #fff;
            }
        }
    }

    .content {
        width: 100%;
        padding: 0 25px;
        border-radius: 30px;
        flex: 1;
        overflow: auto;

        // background-color: #fff;
        .bottom {
            width: 100%;
            height: 74px;
            line-height: 74px;
            // font-size: 32px;
            // background-color: #ffb900;
            // color: #fff;
            color: #333;
            font-size: 26px;
            border-radius: 6px;
            margin-top: 20px;
        }

        .top {
            padding: 20px;
            background-color: #fff;
            border-radius: 20px;
            box-shadow: -1px 2px 20px 0px #333;
            ;
            padding: 30px;
            margin: 30px;

            .title {
                margin-bottom: 20px;
            }

            .c {
                font-size: 22px;
                line-height: 2;
                text-indent: 2em;
                color: #666;
                margin-bottom: 20px;
            }

            .b {
                width: 290px;
                margin: 0 auto;
                font-size: 26px;
                color: #333;
                position: relative;

                .span {
                    margin-left: 5px;
                }
            }
        }
    }
}
</style>