<template>
    <div class="luckDraw">

        <van-nav-bar :title="$t('luckDraw.title')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
            <!-- <template #right>
                <div class="right">中奖记录</div>
            </template> -->
        </van-nav-bar>



        <div class="big-wheel-box" v-if="prizeList.length">

            <big-wheel width="300px" height="300px" ref="bigWheel" :prizeList="prizeList" @over="over">
                <template v-slot:item="{ item }">
                    <div class="prize-name">{{ item.title }}</div>
                    <img class="prize-img" :src="item.image">
                </template>
            </big-wheel>
            <!-- 开始按钮 -->
            <img class="btn-go" @click="go" :src="require('@/assets/go.png')" />
        </div>

        <div class="bullet">
            <div class="bullet_inner">
                <div class="bullet_wrap">
                    <div class="flex-box-x list" v-for="( item, index ) in  luckdrawlog " :key="index">
                        <div class="col-xs-x">
                            <img class="img" width="40px" height="40px" :src="item.image" alt="">

                            <div class="van-ellipsis"> {{ item.mmname }}</div>
                        </div>
                            <div class="col-xs-x van-ellipsis"> {{ item.username }}</div>
                        <div class="col-xs-x">{{ item.add_time | dateFormat }}</div>
                        <!-- <div class="col-xs-x"> {{ item.money }} {{$t('reservation.unit')}}{{$t('my.balance')}}</div> -->
                    </div>
                </div>
            </div>
        </div>
        <van-popup v-model="show" class="popupShow" :close-on-click-overlay="false">
            <template v-if="prizeInfo">

                <img class="img" :src="prizeInfo.image" alt="">
                <div class="title">{{ prizeInfo.title }}</div>
                <div class="msgmemo" v-html="msgmemo"></div>
            </template>

            <div class="btn" @click="clearInfo()">{{ $t('setting.ok') }}</div>
        </van-popup>
    </div>
</template>

<script>
import BigWheel from '../BigWheel/BigWheel.vue';
export default {
    components: {
        BigWheel
    },
    data() {
        return {

            prizeList: [
            ],
            valve: false, // 防止连续点击
            show: false,
            prizeInfo: null,
            msgmemo: '',
            luckdrawlog: []
        };
    },
    created() {
        this.$http({
            method: 'post',
            url: 'luckdrawlist'
        }).then(res => {
            if (res.code === 200) {
                this.prizeList = res.data
            } else if (res.code === 401) {
                this.$toast(res.msg);
            }
        })

        this.$http({
            method: 'post',
            url: 'luckdrawlog'
        }).then(res => {
            if (res.code === 200) {
                this.luckdrawlog = res.data
            } else if (res.code === 401) {
                this.$toast(res.msg);
            }
        })

    },

    filters: {
        dateFormat(val) {
            // 先把传参毫秒转化为new Date()
            const dt = new Date(val * 1000)
            const y = dt.getFullYear()
            // 月份是从0开始,需要+1
            // +''是把数字转化为字符串,padStart(2,'0')是把字符串设置为2位数,不足2位则在开头加'0'
            const m = (dt.getMonth() + 1 + '').padStart(2, '0')
            const d = (dt.getDate() + '').padStart(2, '0')
            return `${m}/${d}/${y}`
        }
    },

    methods: {
        clearInfo() {
            this.show = false
            this.prizeInfo = null
        },
        back() {
            return window.history.back();
        },
        // 开始转动
        go() {
            if (this.valve) {
                return
            }
            this.valve = true
            this.$http({
                method: 'post',
                url: 'luckdraw'
            }).then(res => {
                if (res.code === 200) {
                    // // 转动转盘
                    this.prizeList.map((item, index) => {
                        if (item.id == res.data.id) {
                            this.winningIndex = index
                        }
                    })
                    this.$refs.bigWheel.rotate(this.winningIndex)
                    console.log(res.data)
                    this.msgmemo = res.data.msgmemo
                } else {
                    this.$toast(res.msg);
                }
            })
        },
        // 转盘转完事件
        over(prizeInfo) {
            this.valve = false
            this.prizeInfo = prizeInfo
            this.show = true
        },


    }
};
</script>

<style scoped lang="less">
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
}

.record {
    position: absolute;
    top: 40rpx;
    right: 40rpx;
    color: #fff;
}

.luckdrawconfig {
    position: absolute;
    top: 30%;
    left: 50%;
    width: auto;
    transform: translate(-50%, 0);
    color: #fff;
}



.luckDraw {
    background: linear-gradient(30deg, #7d76ef, #d63f8c);
    position: relative;
    overflow: hidden;
    width: 100vw;
    min-height: 100vh;
    /* background-image: url('~@/assets/wheel_bg.jpg'); */
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;

    .big-wheel-box {
        // position: absolute;
        // top: 1.7rem;
        // left: 50%;
        // transform: translateX(-50%);
        position: relative;
        text-align: center;
        font-size: 0;
        background-image: url('~@/assets/disk_bg.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        margin-top: 100px;
        padding: 80px;

        // margin: 20px;
        .prize-name {
            position: absolute;
            left: 20px;
            right: 20px;
            top: 10px;
            font-size: 20px;
            text-align: center;
            color: #7D2A00;
        }

        .prize-img {
            position: absolute;
            top: 40px;
            left: 50%;
            transform: translateX(-50%);
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }

        .btn-go {
            position: absolute;
            top: calc(50% - 110px);
            left: 50%;
            transform: translateX(-50%);
            width: 200px;
        }
    }
}

.nav-bar .right {
    padding-left: 8px;
    padding-right: 8px;
    color: #fff;
    font-size: 28px;
    border-radius: 10px;
    border: 2px solid #fff;
    line-height: 60px;
}

.popupShow {
    border-radius: 10px;
    padding: 20px;
    margin-top: -80px;

    .img {
        width: 400px;
    }

    .title {
        margin: 10px 0;
        text-align: center;
    }

    .btn {

        background: linear-gradient(30deg, #7d76ef, #d63f8c);
        width: 100%;
        height: 80px;
        color: #fff;
        border-radius: 20px;
        text-align: center;
        font-size: 26px;
        line-height: 80px;
    }
}

.msgmemo {
    margin: 0 0 60px;
    font-size: 26px;
    white-space: pre-wrap;
    color: #775fd9;
    // text-align: center;
}

.bullet {
    width: calc(100% - 60px);
    text-align: center;
    margin: 30px;
    border-radius: 20px;

    .noticeList-title {
        padding: 20px;
        border-bottom: 1px solid #333;
    }

    .bullet_inner {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .bullet_wrap {
            padding-top: 8rpx;
            animation: roll 8s linear infinite;
            height: 600px;

            .img {
                border-radius: 50%;
            }

            .list {
                padding: 10px;
                color: #fff;
                align-items: center;
            }
        }
    }
}

@keyframes roll {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}

.flex-box-x {
    display: flex;

    .col-xs-x {
        flex: 1;
    }
}</style>