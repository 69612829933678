<template>
    <div class="beautys-detail-div page">
        <van-nav-bar class="nav-bar" :title="$t('concubine.concubine')" />
        
            <img style="width: 100%;display: block;" src="/img/au/header_banner.jpg" alt="">
        <div class="beauty-body" style="padding-top: 10px;background: #fff;">
<!-- <img class="banner-gif" src="/img/banner_gif.gif" alt=""> -->
    <!-- <van-tabs v-model="active" line-width="0" @change="tabsChange" >
                <van-tab :title="item" v-for="(item,index) in addlist" :key="index">
                </van-tab>
            </van-tabs> -->
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
        :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <van-list class="hot-xuanfei" v-model="loading" :finished="finished" :offset="50" :immediate-check="false"
          :finished-text="$t('video.no_more')" @load="onLoad" :loading-text="$t('refresh.loading')">
          <div class="hot-items-div" v-if="datalist.length > 0">
            <ul>
              <li v-for="(v, key) in datalist" :key="key" @click="toPlayVideo(v.id)">
                <div class="imgBox">

                  <img :src="v.img_url">
                  <span> <i>{{$t('xuanfei.guidian')}}</i> </span>
                </div>
                <ul>
                  <li>
                    <div>{{ $t('xuanfei.Authentication') }}</div>
                    <div>{{ $t('xuanfei.VideoAuthentication') }}</div>
                    <div>{{v.xuanfei_name}}</div>
                  </li>
                  <li>
                    <div v-for="(li,indexs) in v.service_data" :key="indexs">{{li}}</div>
                  </li>
                  <li><span>{{$t('xuanfei.ChargingRange')}}:</span>
                    <span class="movie-list-money">{{ v.price }}</span>
                    
                    <!-- <div><span></span><span></span><span></span><span></span><span></span></div> -->
                  </li>
                  <li><span>{{$t('xuanfei.height')}}:{{v.height}} {{$t('xuanfei.round1')}}: {{v.one}}  {{$t('xuanfei.round2')}}: {{v.two}}  {{$t('xuanfei.round3')}}: {{v.three}}  {{$t('xuanfei.serveCity')}}:{{v.address}}

                    <!-- 空輸：はい -->

                  </span></li>
                  <li>{{$t('xuanfei.DateHer')}} </li>
                  
                </ul>
              </li>
            </ul>
          </div>
        </van-list>

      </van-pull-refresh>
      <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
        :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <van-list class="hot-xuanfei" v-model="loading" :finished="finished" :offset="50" :immediate-check="false"
          :finished-text="$t('video.no_more')" @load="onLoad">

            <div class="beauty-content-div">
                        <div class="beauty-item-div" v-for="(item,index) in datalist" :key="index" @click="toPlayVideo(item.id)">
                            <div class="bg-img-div" ><img
                                    :src="item.img_url"
                                    >
                                <div class="beauty-item-bottom-div" >
                                    <div ><van-icon color="#fff" name="location-o" /><span >{{item.address}}</span></div>
                                </div>
                            </div>
                            <div class="describe-div" >
                                <ul style="display: flex;">
                                    <li ><span >{{ item.xuanfei_name }}</span></li>
                                    <li v-if="item.service_data.length > 0">
                                        <div><i ></i>
                                            <span>
                                                >{{item.service_data[0]}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
        </van-list>

      </van-pull-refresh> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: 0,
            addlist: [],
            datalist:[],
            isLoading: true,
      loading: false,
      finished: false,
      page:1,
      count:0,
            active_id:''
        };
    },
    methods: {
        toPlayVideo(id) {

        if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
            this.$router.push({ path: '/Login' })
        } else {
            if (id) {
            this.$router.push({ path: '/nHomeDetail?id=' + id })
            }
        }
        },
        getAddress() {
            this.$http({
                method: 'get',
                url: 'address_list'
            }).then(res => {
                console.log(res)
                this.addlist = res.data;
            this.active_id = res.data[0].id
                this.getxuanfeilist()
            });
        },
        tabsChange(e){
            this.datalist = []
            this.active_id = this.addlist[e].id
            this.getxuanfeilist()
        },
        onRefresh() {
        setTimeout(() => {
            this.page = 1
            // this.datalist = []
            this.getxuanfeilist();
            this.isLoading = false;
            this.$toast(this.$t('reservation.refresh'));
        }, 500);
        },
        onLoad() {
        this.loading = true;
        this.getxuanfeilist();
        let timer = setTimeout(() => {
            if (this.refreshing) {
            this.datalist = [];
            this.refreshing = false;
            }
            // this.loading = false;
            // if (this.datalist.length === this.count) {
            // this.finished = true;
            // }
            this.finished && clearTimeout(timer);//清除计时器
        }, 500);
        },
		getxuanfeilist() {
            
            this.$http({
                method: 'get',
                url: 'xuanfeilist',
                data: { page: this.page,id:this.active_id },
            }).then(res => {
                console.log(res.data)
                this.isLoading = false;
                this.loading = false;
                if (this.page == 1) {
                this.datalist = res.data;
                } else {

                this.datalist = this.datalist.concat(res.data);
                }
                if (res.data.length == 0) {
                this.finished = true;
                } else {
                this.finished = false;
                }
                this.count = res.data.count;
                this.page++;
            })
		}
    },
    created() {
        this.getAddress();
    }
};
</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;


.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}

.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}

.banner {
  width: 100%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.hot-game {
  width: 100%;
  height: 100%;
}

.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 100px;
  display: flex;
  align-items: center;
}

.hot-title-div>div:first-child {
  width: 430px;
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #c24491;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #c24491;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 25px;
  color: #c24491;
}

.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #775fd9;
  border-radius: 1px;
  margin-right: 5px;
}

.hot-game .hot-items-div {
  margin-top: -3px;
}

.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 24px;
  color: #c24491;
}



.hot-items-div {
  // padding: 12px 0;
  padding: 0 10px;
}

::v-deep .hot-items-div .van-grid-item__content {
  padding: 10px;
}

.hot-items-div .game_item_img {
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.luckDraw {
  position: fixed;
  bottom: 160px;
  right: 40px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  z-index: 999;
}

.kefu {
  position: fixed;
  bottom: 300px;
  right: 60px;
  width: 80px;
  height: 80px;
  z-index: 999;
  // background: #fff;border-radius: 50px;
}

/deep/.van-popup__close-icon {
  font-size: 50px;
}

.pop_content-content {
  font-size: 30px;
  color: #1c1e23;
  padding: 30px;
  margin-top: 40px;
}





.movie-list-n-img {
  width: 330px;
  height: 330px;
  position: relative;
}

.bd-info .info-model .model-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background-color: #8f65fe;
  border-radius: 0.5rem;
  margin: 0.625rem;
  padding: 0.625rem;
}

.bd-info .info-model .model-item .item-pic {
  position: relative;
  width: 7.5rem;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 0.625rem;
}

.bd-info .info-model .model-item .item-info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: .75rem;
}

.bd-info .info-model .model-item .item-pic img {
  width: 7.5rem;
  height: 11.25rem;
  border: 0;
  vertical-align: middle;
}

.bd-info .info-model .model-item .item-info .name {
  margin-bottom: 0.625rem;
  margin-top: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag span {
  font-size: .75rem;
  color: #fff;
  display: inline-block;
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
  background-color: #ea92cf;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .info {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .remark {
  // height: 2.625rem;
  line-height: 1.6;
  overflow: hidden;
  margin-bottom: 0.75rem;

  display: -webkit-box;

  overflow: hidden;

  white-space: normal !important;

  text-overflow: ellipsis;

  word-wrap: break-word;

  -webkit-line-clamp: 3;

  -webkit-box-orient: vertical
}

.bd-info .info-model .model-item .item-info .call .van-button {
  width: 100%;
  height: 2.125rem;
  border-radius: 6.1875rem !important;
}

.bd-info .info-model .model-item .item-info .remark span {
  color: #ddd2fb;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .name span {
  display: inline-block;
  color: #fff;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .info span {
  color: #ddd2fb;
  font-size: .875rem;
}

.van-button--info,
.van-button--primary {
  border: none !important;
  background: -webkit-gradient(linear, left top, right top, from(#a082f0), to(#ea92cf)) !important;
  background: linear-gradient(90deg, #a082f0, #ea92cf) !important;
  border-radius: 5px !important;
}





.movie-list-notice {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.movie-list-addr {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 28px;
}

.movie-list-ico-addr {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}

@keyframes rainbow_animation {

  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

.movie-list-money {
  background: linear-gradient(to right, #6666ff, #0099ff, #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  animation: rainbow_animation 10s ease-in-out infinite;
  background-size: 400% 100%;
  font-weight: bold;
  position: relative;
  margin-right: 10px;
}

.movie-list-ico-money {
  width: 30px;
  display: inline-block;
  margin-right: 10px;

}



.home-top-bar {
  width: 100%;
  padding: 0 30px !important;
  justify-content: space-between;
  // background: url(../../assets/topbar_bg.png);
  background: linear-gradient(30deg, #7d76ef, #d63f8c);
  background-size: cover;
  background-position: 50%
}

.home-top-bar .logo-div,
.home-top-bar {
  display: flex;
  align-items: center
}

.home-top-bar .logo-div img {
  height: 100px;
  -o-object-fit: contain;
  object-fit: contain
}

.home-top-bar .login-between-div,
.home-top-bar .login-div,
.home-top-bar .login-left-div {
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.home-top-bar .login-between-div .login-span,
.home-top-bar .login-div .login-span,
.home-top-bar .login-left-div .login-span {
  margin-right: 40px
}

.home-top-bar .login-left-div i {
  opacity: 0
}

.home-top-bar .server-msg-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px
}

.home-top-bar i {
  color: #fff
}




.hot-game .hot-items-div {
  // width: 2.93333rem;
  // height: 2.93333rem;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: space-between;
  overflow: hidden;
  // margin-bottom: 20px;
  position: relative;
}

.hot-game .hot-items-div img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover
}

.hot-game .hot-items-div .absolute-span {
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  width: 192px;
  height: 192px;
  transform: rotate(45deg);
  top: -120px;
  right: -120px;
}

.hot-game .hot-items-div .absolute-span i {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 10px);
  transform: translate(-50%, 0);
  font-size: 16px;
  font-weight: 600
}



.hot-game {
  width: 100%;
  background-color: #fff
}

.hot-game>div {}

.hot-game .hot-title-div {
  height: 80px;
  display: flex;
  align-items: center;
  width: calc(100% - 30px);
  margin: 0 auto
}

.hot-game .hot-title-div>span {
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hot-game .hot-title-div>div {
  margin-left: 20px;
  border-radius: 20px 0 20px 0;
  background: #3f3a5b;
  color: #ebcaaf;
  padding: 4px 10px;
  font-size: 22px
}

.hot-game .hot-title-div .hotganme-img {
  height: .64rem;
  margin-right: 6px;
  margin-top: -.10667rem
}





.hot-xuanfei {
  width: 100%;
  background-color: #fff
}

.hot-xuanfei>div {
  width: calc(100% - 50px);
  margin: 0 auto
}

.hot-xuanfei .hot-title-div {
  height: 1.06667rem;
  display: flex;
  align-items: center
}

.hot-xuanfei .hot-title-div>span {
  font-size: .42667rem
}

.hot-xuanfei .hot-title-div .hotganme-img {
  height: .64rem;
  margin-right: 6px;
  margin-top: -.10667rem
}

.hot-xuanfei .hot-title-div>div:first-child {
  width: 5.33333rem;
  display: flex;
  align-items: center
}

.hot-xuanfei .hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: .37333rem;
  font-weight: 700;
  color: #000
}

.hot-xuanfei .hot-title-div .no-hotgameImg-div span:before {
  content: "";
  display: block;
  width: 8px;
  height: .37333rem;
  background-color: #8e4ff1;
  border-radius: 6px;
  margin-right: 8px
}

.hot-xuanfei .hot-title-div>div:nth-child(2) span {
  font-size: .34667rem;
  color: #ed37ae
}

.hot-xuanfei .hot-title-div>div:nth-child(2) .van-icon {
  color: #ed37ae
}

.hot-xuanfei .hot-items-div {
  margin-bottom: 20px;
  display: flex
}

.hot-xuanfei .hot-items-div>ul {
  flex: 1;
  display: flex;
  flex-direction: column
}

.hot-xuanfei .hot-items-div>ul>li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px
}

// .hot-xuanfei .hot-items-div>ul>li>img {
//   width: 200px;
//   height: 200px;
//   -o-object-fit: cover;
//   object-fit: cover;
//   border-radius: 6px;
//   margin-right: 20px
// }

.hot-xuanfei .hot-items-div>ul>li>ul {
  flex: 1
}

.hot-xuanfei .hot-items-div>ul>li>ul>li {
  display: flex;
  align-items: center
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:first-child {
  // height: 50px;
  margin-bottom: 12px;
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:first-child>div:first-child,
.hot-xuanfei .hot-items-div>ul>li>ul>li:first-child>div:nth-child(2) {
  background: linear-gradient(180deg, #e7caaf, #fff7ed);
  color: #a4826b;
  font-weight: 600;
  padding: 0 6px;
  font-size: 20px;
  border-radius: 6px 0 6px 0;
  margin-right: 6px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:first-child>div:nth-child(3) {
  font-size: 24px;
  margin-left: 20px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(2) {
  flex-wrap: wrap
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(2)>div {
  height: 50px;
  background-color: #fc2372;
  color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-right: 6px;
  margin-bottom: 12px;
  font-size: 24px;
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(2)>div:nth-child(2n) {
  background-color: #ff8b24
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(2)>div:nth-child(3n) {
  background-color: #009ff9
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(3) {
  margin-bottom: 6px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(3)>span {
  font-size: 24px;
  margin-right: 20px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(3)>div {
  display: flex
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(3)>div span {
  display: block;
  width: 24px;
  height: 24px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABl0lEQVRIie3WMUhVURzH8c97WQg6VJIICeLUENIgDa+GmnSQCCKIHINqCkRoKWiwRVtcogjMzcVJHWrt4RBOWWu4iLW0NIRD8cjh3IeHy70+37vvbv7gDPd//ud+/3/O+f/Pqfz5eE8Heoo1fG93YbUD2CXM4XUHa9sGVvAOvZjA3bKBD3Aj+l5Ef1nAQbxK2YbxoizgIs5n2GdwudvASUznzJ0WDlClG8AqxvCmhd9NPMKpVsBKqg77cBXXUcM1nGv1k0i/sYlPqGMbjdihB7eTCGsYT2yd6ixuJSMOoJ4Esd2DAWHjy1AcwC/UqljGQknApvYT6E7z0DzHRkmwBu5ji8NT2jR+KwH4RJRMXBb7mMLPLsLm8TY2pOtwD3cSeFGt4FnamFX4W0IRF9Gm0Oj/HwcIXwoCP+Bv1kQecKggMKvJHwm8UBCY2w5PMszSrnAZH9Uk2gamM2xgXWgMo5jFFeGWWcW/lP9AHjDvKmpmuIslvJfdgerJuIjHeJisbXsPPzvM5mUOLNYP4TE1IjxFvuJMluMB65BGG6uhkFoAAAAASUVORK5CYII=) no-repeat 50%/100%
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(4) {
  margin-bottom: 12px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(4) span {
  color: #999;
  font-size: 24px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(5) {
  background: linear-gradient(90deg, #df35ad, #4f1db5);
  color: #fff;
  border-radius: 10px;
  padding: 12px 0;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.imgBox{
  position: relative;
    overflow: hidden;
    border-radius: 1.067vw;
    margin-right: 2.667vw;
  width: 240px;
  height: 240px;
}
.imgBox span{
  position: absolute;
    z-index: 1;
    background-color: #ebcaaf;
    color: #8d684b;
    transform: rotate(45deg);
    width: 120px;
    height: 120px;
    font-size: 22px;
    top: -60px;
    right: -60px;
}
.imgBox>span>i {
    position: absolute;
    bottom: .667vw;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-size: 26px;
}
.imgBox img{

  width: 240px;
  height: 240px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 20px
}
.hot-game .hot-items-div .game_item_name1{
  position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%,0);
    text-align: center;
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 24px;

}

.hot-title-div span:before {
    content: "";
    display: block;
    width: .667vw;
    height: 4vw;
    background-color: #7e5678;
    border-radius: 1px;
    margin-right: .667vw;
}

::v-deep .van-tab {
  // color: #ffffff;
  color: #442889;
  font-size: 30px;
}
::v-deep .van-tabs__line {
  bottom: 15px;
  width: 55px;
  height: 7px;
  border-radius: 0px;
  background-color: #ee0a24;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
</style>